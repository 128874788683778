import { FunctionComponent, useEffect } from 'react';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { AuthPage } from '../../app/layout';
import { useAsyncState } from 'app/hooks/useAsyncState';
import { getSignInToken } from 'firebaseApp';
import Typography from '@mui/material/Typography';
import { FlatButton } from 'components/FlatButton';
import Box from '@mui/material/Box';

const TokenComponent: FunctionComponent = () => {
  // The state stored is whether the token has expired or not
  const [getTokenState, success, failure, waiting] = useAsyncState<boolean>();
  const onTokenSuccess = (result: Awaited<ReturnType<typeof getSignInToken>>) => {
    if ('expired' in result.data) {
      success(true);
      return;
    }
    success(false);
    signInWithCustomToken(getAuth(), result.data.token);
  };

  const errorToRender =
    getTokenState.error && typeof getTokenState.error === 'object' ? getTokenState.error.message : null;

  useEffect(() => {
    const token = window.location.hash;
    if (getTokenState.status !== undefined) {
      return;
    }
    if (!token?.startsWith('#')) {
      return;
    }
    waiting();
    getSignInToken({ jwt: token.substring(1) }).then(onTokenSuccess, failure);
  }, [getTokenState, getSignInToken, waiting, onTokenSuccess, failure]);

  return (
    <AuthPage>
      {getTokenState.status === 'pending' ? <>Signing you in...</> : null}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
        {getTokenState.status === 'failure' ? (
          <>
            Error signing you in: <Typography color="error">{errorToRender}</Typography>
          </>
        ) : null}
        {getTokenState.result === true ? <>This token has expired</> : null}
        {getTokenState.status === 'failure' || getTokenState.result === true ? (
          <FlatButton href="/auth/signin">Get a new token here</FlatButton>
        ) : null}
      </Box>
    </AuthPage>
  );
};

export default TokenComponent;
